

import { User, applyActionCode, sendEmailVerification, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { navigate } from 'gatsby';
import React, { ReactNode } from 'react'
import { auth, db } from '../../../firebase-config';
import CobraHeader from '../../client-ui/shared/CobraHeader';
import { LayoutFS } from '../../locust/composables/Layout';
import { getShoppingCart } from '../../service/shopping-cart';
import { ToastContainer } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import SpacerV from '../../locust/sections/SpacerV';
import { AuthLogoLgCentered, SubtitleIIRsp, SubtitleRsp, TitleRspAlt } from '../../locust/components/Auth';
import { FACEBOOK_BLACK } from '../../assets/colors/Colors';
import X from '../../locust/composables/X';
import { Button } from '@mui/material';
import { getCurrentUser, getUIDMain } from '../../service/user-service';
import { getProcessFS, setPageExistsFS } from '../../service/firestore-service';
import { doc, setDoc } from 'firebase/firestore';
import { getTempDataFBPortal_TEMP_00 } from '../../../firebase/firebase-portal-temp-00';
import { ENABLE_ERROR_LOGGING, ENDPOINT_128, ENDPOINT_256 } from '../../settings/project-settings';
import { indexOfNth } from '../../locust/composables/Shared';

enum PageState {
    PROCESSING,
    OOB_CODE_STALE,
    PROVISIONING, 
    SUCCESS,
    FAIL,
    ERROR,
    ALREADY_REGISTERED,
    CHECK_YOUR_EMAIL,
}

enum Constants {
    GUTTER = 40,
    FIELD_GUTTER = 16,
    FINEPRINT_GUTTER = 8
}

const Content = {
    signup : {
        title : `It's Who You Know`,
        subtitle : 'Registering your account...',
        fineprint  : 'By signing in, you agree to the Terms of Service and Privacy Policy, including Cookie Use.',
        hasAccount : `Don't have an Account?`,
        signUpBtn : 'Sign In'
    }
}

const ProgressValue = (props : {  on ?: boolean } ) => 
    props.on ? <CircularProgress style={{ height : 40, width : 40, color : FACEBOOK_BLACK}}/>
    : <></>

const RegisterButton = ( props : {
    onClick ?: () => void,
    children ?: any 
}) => <Button
    style={{ 
        width : 350, 
        height : 55, 
        marginLeft : 10,
        marginRight : 10,
        color: 'white', 
        backgroundColor: FACEBOOK_BLACK,
        border : `2px solid ${FACEBOOK_BLACK}`

    }}
    type="submit"
    variant="contained"
    onClick={props.onClick}
>{props.children}</Button>



const handleStaleOOBCode = ( props : {
    setPageState : ( value : PageState ) => void,
    setSubtitle : ( value : string ) => void,   
    setActivationFailedMsg : ( value : string | ReactNode ) => void,   
}) => {
    // [TO-DO][COLLECT-DATA] : hook up a form to get the email address of any failed attempts here. 
    props.setPageState( PageState.OOB_CODE_STALE )
    props.setSubtitle( "Your activation code is expired.")
    props.setActivationFailedMsg(<div style={{ textAlign : 'center'}}>Check your email. <br/>We just sent you a new one <br/> with some special sauce.</div>)
    sendEmailVerification( getCurrentUser() as User ).catch(( error )=>{ /* [TO-DO]: IS THIS WORTH HANDLING? */ }) 
}


export default () => {
    const [pageState, setPageState] = React.useState(PageState.PROCESSING)
    const [ subtitle, setSubtitle ]  = React.useState(Content.signup.subtitle)
    //const [awaitingSignin, setAwaitingSignin] = React.useState(false)
    //const [username, setUsername] = React.useState("")
    //const [password, setPassword] = React.useState("")
    //const [passwordVerify, setPasswordVerify] = React.useState("")
    const [activationFailedMsg, setActivationFailedMsg] = React.useState<string|ReactNode>("Your activation code appears to be invalid.")                 
    
    //[TO-DO] : THIS REQUIRES COOKIES TO SAVE PASSWORD AND RESIGN IN. SHOULD THIS BE DONE WITH URL REWRITING INSTEAD?


    // what if not signed in ?
    React.useEffect(()=>{

        

        


        setTimeout( async ()=>{
        
            const params = new URLSearchParams(window.location.href);
            const oobCode : any = typeof params.get("oobCode") === "string" ? params.get("oobCode") : ""

            

            const index1 : number = window.location.href.indexOf('email=') !== -1 ? window.location.href.indexOf('email=') + ('email=').length : -1 
            const index2 : number = index1 !== -1 ? window.location.href.indexOf( "&" , index1 + 1) : -1
            const email : string = index1 !== -1 && index2 !== -1 ? window.location.href.substring(index1, index2) : 'undefined'
            
            
            if( ENABLE_ERROR_LOGGING ) console.log('email: ' + email )
            const data : any = await getTempDataFBPortal_TEMP_00( email.toLowerCase() )
            const pw : any = data ? data.password : undefined
            const uid : any = data ? data.uid : undefined
            const redirect : any =  data ? data.redirect : undefined
            const resumeDataResponse : any = await getProcessFS( uid )
            const registrationState = resumeDataResponse && resumeDataResponse.registration ? resumeDataResponse.registration : 1
            if( ENABLE_ERROR_LOGGING ) console.log( 'registrationState: ' + registrationState  )    
                
            
            if(oobCode){


                

                applyActionCode( auth, oobCode ).then( async ( response )=>{

                    setPageState(PageState.SUCCESS)
                    setTimeout(()=>{
                        navigate('/auth/sign-in')
                    },1000)
                    //

                }).catch(async ( error )=>{
                    if(error.code === "auth/invalid-action-code"){
                        handleStaleOOBCode({
                            setPageState, 
                            setSubtitle, 
                            setActivationFailedMsg
                        })
                    } 
                })
            }
                
            else setPageState(PageState.ERROR)

        },1000)

    },[])

    return (
        <>

            <LayoutFS min={350}>
                <CobraHeader type={'store'} shoppingCart={Object.keys(getShoppingCart()).length}/>
                

                <SpacerV h={Constants.GUTTER}/>
                <AuthLogoLgCentered/>
                <SpacerV h={Constants.GUTTER}/>
                
                <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
                <SubtitleRsp c={FACEBOOK_BLACK}>{subtitle}</SubtitleRsp>
                    
                <SpacerV h={Constants.GUTTER}/>

                {pageState === PageState.PROCESSING && 
                    <>
                        <X>
                            <ProgressValue on/>
                        </X>
                    </>
                }

                {pageState === PageState.OOB_CODE_STALE && 
                    <>
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            {activationFailedMsg}
                        </SubtitleIIRsp>
                    </>
                }

                {pageState === PageState.PROVISIONING && 
                    <>
                        <X>
                            <ProgressValue on/>
                        </X>
                       
                    </>
                }

                {pageState === PageState.FAIL && 
                    <>
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            {activationFailedMsg}
                        </SubtitleIIRsp>
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <X style={{ height : 55 }}>
                            <Button
                                style={{ 
                                    width : 350, 
                                    height : 55, 
                                    marginLeft : 10,
                                    marginRight : 10,
                                    color: 'white',
                                    backgroundColor: FACEBOOK_BLACK, 
                                    border : `2px solid ${FACEBOOK_BLACK}`

                                }}
                                type="submit"
                                
                                variant="contained"
                                onClick={async ()=>{
                                    const user  = getCurrentUser()
                                    if(user) sendEmailVerification( user ).then(()=>{
                                        setActivationFailedMsg("Check your email in a moment")

                                    }).catch(( error )=>{
                                        
                                        if(error.code === "auth/too-many-requests") navigate("/auth/attempts")
                                    })
                                }}
                            >Resend?</Button>
                        </X>
                      
                            
                    </>
                }

                {pageState === PageState.ERROR && 
                    <>
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            An unknown error occured. Try again later.
                        </SubtitleIIRsp>
                      
                            
                    </>
                }
                
                {pageState === PageState.SUCCESS && 
                    <>
                        
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            You're Registered! Click to continue.
                        </SubtitleIIRsp>
                        <SpacerV h={Constants.FINEPRINT_GUTTER}/>
                        <X style={{ height : 55 }}>
                            <RegisterButton onClick={()=>{ navigate("/dashboard") }}>
                                Continue
                            </RegisterButton>
                        </X>
                        
                    </>
                }

                {pageState === PageState.ALREADY_REGISTERED && 
                    <>
                        
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            You're already registered. Click to continue.
                        </SubtitleIIRsp>
                        <SpacerV h={Constants.FINEPRINT_GUTTER}/>
                        <X style={{ height : 55 }}>
                            <RegisterButton onClick={()=>{ navigate("/dashboard") }}>
                                Continue
                            </RegisterButton>
                        </X>

                        
                            
                    </>
                }

                


                {/* <Register/> */}
            </LayoutFS>
            <ToastContainer hideProgressBar autoClose={2000} position="bottom-right"/>
        
        </>
    )
}

const onRegisterInitializeDefaultPage = async ( token : string ) => {
    if( ENABLE_ERROR_LOGGING ) console.log("token: " + token ) 
    await setPageExistsFS({ 
        pageId : getUIDMain() as string, 
        value : { locked : true, owner :  auth?.currentUser?.uid },
        onSuccess : async ( response ) => {
            if( ENABLE_ERROR_LOGGING ) console.log("changed owner successfully... attempting to create card")
            const ref = doc(db, `registration/${auth.currentUser?.uid}`)    
            await setDoc(ref, {
                status : true,
                token : token, 
            },{ merge : true }).catch(( error )=>{
                if( ENABLE_ERROR_LOGGING ) console.log(error)
            })
            // await setPageExistsFS_portalStorage00({ 
            //     pageId : getUIDMain() as string, 
            //     value : { locked : true, owner :  portalAuthStorage00?.currentUser?.uid },
            // })

        }
    })
}

